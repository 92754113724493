import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Box } from "ticeboxes"
import { H1 } from "../components/general/Typography"
import Layout from "../components/layout/Layout"
import Head from "../components/layout/Head"
import styled from "styled-components"

const StyledIframe = styled.iframe`
  width: 100%;
  height: 950px;
  border: none;
  @media (min-width: 576px) {
    height: 800px;
  }
  @media (min-width: 992px) {
    height: 700px;
  }
  @media (min-width: 1200px) {
    height: 650px;
  }
`

const afspraakmaken: React.FC = () => {
  return (
    <>
      <Head
        title="Online afspraak maken"
        description="Maak een afspraak gemakkelijk online bij Eyefashion By Rami"
      ></Head>
      <Layout>
        <Container>
          <Row className="mb-5">
            <Col xs="12" className="text-center text-md-left">
              <Box mt="5" mb="4" mb-md="5">
                <H1>Afspraak maken</H1>

                <Box mt="5">
                  <StyledIframe
                    src="https://eyefashionbyrami.oo2.online"
                    frameborder="0"
                  ></StyledIframe>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default afspraakmaken
